/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import EmailSignup from '@/components/ContentBlocks/EmailSignup';
import { useAnalytics } from '@/utils/analytics/useAnalytics';
import { usePageViewCall } from '@/utils/analytics/usePageViewCall';
import { getContentBlockNames } from '@/utils/analytics/legacyUtils/getContentBlockNames';
import { getContentBlockTypes } from '@/utils/analytics/legacyUtils/getContentBlockTypes';
import { getPageName } from '@/utils/analytics/legacyUtils/getPageName';
import { getSeoPageType } from '@/utils/analytics/legacyUtils/getSeoPageType';
import { pageTypes } from '@/utils/analytics/legacyUtils/pageTypes';
import { getAnalyticsRelativeComplementOf } from '@/utils/analytics/getAnalyticsRelativeComplementOf';
import { ScrolledPartCardSkeleton } from '@/features/certona';
import { useGetCertonaRecommendations } from '@/features/certona/api/useGetCertonaRecommendations';
import { certonaPageType } from '@/constants/certonaPageType';
import { selectOrderDetailsFromState } from '@/constants/selectors';
import { useSelector } from 'react-redux';
import { useLocale } from '@/hooks/useLocale';
import { getCartridgeType } from '@/utils/getCartridgeType';
import { Suspense, useEffect, useRef, useState } from 'react';
import { useHomePageContent } from '../api/getHomePageContent';
import { useHomePageV2Content } from '../api/getHomePageContentV2';
import { siteSections } from '@/utils/siteSections';
import { useHeaderData } from '@/features/header/api/getHeader';
import HeadTemplate from '@/components/PageTemplates/Head';
import { CMSModularHandler } from './CMSModularHandler';
import { CMSSeoTags } from '@/features/contentstack/components/CMSSeoTags/CMSSeoTags';
import { useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';
import { countryCodes } from '@/constants/locale';
import { type AddCertonaItemObjectType } from '@/features/certona';
import styles from './HomePageTemplate.module.scss';
import { Drawer } from '@/components/Drawer/Drawer';
import { TrapFocus } from '@/components/Dialog/Modal/TrapFocus';
import ProgressModal from '@/components/AZCustomComponent/ProgressModal';
import dynamic from 'next/dynamic';
import { formatPartTypeId } from '@/utils/formatPartTypeId';
import { useFirstScrollDetected } from '@/hooks/useFirstScrollDetected';
import { useDeviceType } from '@/utils/useDeviceType';
import { useFeatureFlag } from '@/features/globalConfig';
import { useContentStackLivePreview } from '@/hooks/useContentStackLivePreview';

const LazyMiniCartFlyoutComp = dynamic(
  () => import('@/features/miniCartFlyout').then((mod) => mod.MiniCartFlyoutComp),
  {
    loading: () => <ProgressModal noScroll />,
  }
);

const LazyPreFooter = dynamic(() =>
  import('@/features/prefooter/components/PreFooter').then((mod) => mod.PreFooter)
);

const LazyCMSContentBlock = dynamic(() =>
  import('./CMSContentBlock').then((mod) => mod.CMSContentBlock)
);

export const HomePageTemplate = ({ isPreview }: { isPreview: boolean | undefined }) => {
  const isBot = useDeviceType() === 'bot';
  const shouldRenderPreFooter = useFirstScrollDetected(isBot);
  const locale = useLocale();
  const contentStackHomePageEnabled =
    useMonetateDecisionFlag('cmsHomeEnabled') || locale === countryCodes.ptBr;
  const isHomePageCertonaAddToCartEnabled =
    useFeatureFlag('IS_HOME_PAGE_CERTONA_ADD_TO_CART_ENABLED') === 'true';

  const contentQuery = useHomePageContent({ enabled: !contentStackHomePageEnabled });
  const { data: contentStackData, isSuccess: contentStackSuccess } = useHomePageV2Content({
    enabled: contentStackHomePageEnabled,
  });
  const hasSetupAnalytics = useAnalytics();
  const pageViewCall = usePageViewCall();
  const { data: headerData } = useHeaderData();
  const lazyMiniCartModalRef = useRef<HTMLDivElement>(null);
  const [pageViewCalled, setPageViewCalled] = useState(false);
  const homeHead = contentQuery.isSuccess ? contentQuery.data.homeHead : [];
  const vehicleId = headerData?.vehicleMap.catalogVehicleId || '';
  const [addedCertonaItemObject, setAddedCertonaItemObject] =
    useState<AddCertonaItemObjectType | null>(null);
  const orderDetailsFromState = useSelector(selectOrderDetailsFromState);
  const [showCertonaMiniCartFlyoutModal, setShowCertonaMiniCartFlyoutModal] =
    useState<boolean>(false);
  const [certonaRecommendationAddedToCart, setCertonaRecommendationAddedToCart] = useState(false);
  const storeNumber = headerData?.storeNumber;
  const miniCartMap = headerData?.miniCartMap;
  const certonaRecommendations = useGetCertonaRecommendations(
    {
      certonaType: certonaPageType.homePage,
      vehicleID: vehicleId,
      ...(!!certonaRecommendationAddedToCart && {
        parttype: formatPartTypeId(addedCertonaItemObject?.productDetails.partType),
      }),
    },
    [vehicleId, storeNumber]
  );
  const closeStoreModal = () => {
    if (!!addedCertonaItemObject) {
      setCertonaRecommendationAddedToCart(false);
      setAddedCertonaItemObject(null);
    }
  };

  const toggleCertonaMiniCartFlyout = () => {
    setShowCertonaMiniCartFlyoutModal(() => !showCertonaMiniCartFlyoutModal);
    closeStoreModal();
  };

  useEffect(() => {
    if (!!addedCertonaItemObject) {
      setShowCertonaMiniCartFlyoutModal(true);
    }
  }, [addedCertonaItemObject]);

  useContentStackLivePreview({ enabled: contentStackHomePageEnabled });

  useEffect(() => {
    if (contentQuery.isSuccess && hasSetupAnalytics && !pageViewCalled) {
      const { mainContent: content, homeHead } = contentQuery.data;
      const pageSpecificAnalyticsData = {
        pageName: getPageName(pageTypes.home, ''),
        siteSection: siteSections.siteSectionHome,
        pageType: pageTypes.home,
        pageHierarchy: 'Home',
        contentBlockNames: [...getContentBlockNames(homeHead), ...getContentBlockNames(content)],
        contentBlockTypes: [...getContentBlockTypes(homeHead), ...getContentBlockTypes(content)],
        seoPageType: getSeoPageType(pageTypes.home, ''),
      };
      pageViewCall(
        pageSpecificAnalyticsData,
        getAnalyticsRelativeComplementOf(pageSpecificAnalyticsData, undefined)
      );
      setPageViewCalled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentQuery.isSuccess, contentQuery.data, hasSetupAnalytics]);

  useEffect(() => {
    if (contentStackSuccess && hasSetupAnalytics && !pageViewCalled) {
      const pageSpecificAnalyticsData = {
        pageName: 'az:home',
        siteSection: siteSections.siteSectionHome,
        pageType: pageTypes.home,
        pageHierarchy: 'Home',
        seoPageType: 'HOME',
      };
      pageViewCall(
        pageSpecificAnalyticsData,
        getAnalyticsRelativeComplementOf(pageSpecificAnalyticsData, undefined)
      );
      setPageViewCalled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentStackSuccess, contentStackData, hasSetupAnalytics]);

  const content = contentQuery.isSuccess ? contentQuery.data.mainContent : undefined;

  if (content?.length === 0 && contentQuery.isSuccess) {
    return null;
  }

  return (
    <>
      {!contentStackSuccess &&
        content?.map((contentItem, i) => {
          const contentType = getCartridgeType(contentItem['@type']);

          if (contentType === 'Slot' && contentItem.contents?.length !== 0) {
            return contentItem.contents?.map((singleContentItem, mappedContentIndex) => {
              const singleContentType = getCartridgeType(singleContentItem['@type']);
              const key = `${singleContentType}-${mappedContentIndex}`;

              return (
                <LazyCMSContentBlock
                  key={key}
                  contentType={singleContentType}
                  contentItem={{ ...singleContentItem, contentType: singleContentType }}
                  index={mappedContentIndex}
                  certonaRecommendations={certonaRecommendations}
                />
              );
            });
          }

          const key = `${contentType}-${i}`;

          return (
            <LazyCMSContentBlock
              key={key}
              contentType={contentType}
              contentItem={{ ...contentItem, contentType }}
              index={i}
              certonaRecommendations={certonaRecommendations}
            />
          );
        })}
      {contentStackSuccess && contentStackData.homepageBody ? (
        <>
          <CMSModularHandler
            content={contentStackData.homepageBody}
            certonaRecommendations={certonaRecommendations}
            contentSeo={contentStackData.seoTags}
            setAddedCertonaItemObject={setAddedCertonaItemObject}
            setCertonaRecommendationAddedToCart={setCertonaRecommendationAddedToCart}
            onlyShowAvailableProductRecommendations={isHomePageCertonaAddToCartEnabled}
            LoadingSkeleton={ScrolledPartCardSkeleton}
          />
          <div className={styles.miniCartPopup}>
            <Drawer
              data-testid="mini-cart-flyout"
              anchor={'right'}
              open={showCertonaMiniCartFlyoutModal}
              onClose={toggleCertonaMiniCartFlyout}
              PaperProps={{ className: styles.customPaperDrawer }}
              drawerRef={lazyMiniCartModalRef}
            >
              <TrapFocus open={showCertonaMiniCartFlyoutModal}>
                <div className={styles.miniCartFlyoutDialog} role="dialog" aria-modal="true">
                  <Suspense>
                    {showCertonaMiniCartFlyoutModal && addedCertonaItemObject && (
                      <LazyMiniCartFlyoutComp
                        handleClose={toggleCertonaMiniCartFlyout}
                        isModalOpen={showCertonaMiniCartFlyoutModal}
                        image={addedCertonaItemObject.productImage}
                        successData={addedCertonaItemObject.cartSuccessData}
                        productDetails={{
                          productName: addedCertonaItemObject.productDetails.productName,
                          certonaItemAddedTocart:
                            certonaRecommendationAddedToCart && isHomePageCertonaAddToCartEnabled,
                          productPartType: addedCertonaItemObject.productDetails.partType,
                          originalPartTypeId: addedCertonaItemObject.productDetails.partType,
                          productId: addedCertonaItemObject.productDetails.productId,
                          productAvailabilityInfo: {
                            aZBadgesFlagVO: null,
                            dealsInfo: undefined,
                            skuPricingAndAvailability: undefined,
                          },
                        }}
                        miniCartData={miniCartMap}
                        closeStoreModal={closeStoreModal}
                        cartUrl={addedCertonaItemObject.submitToCart}
                        productTitle={addedCertonaItemObject.productTitle}
                        pageName={addedCertonaItemObject.pageName}
                        orderDetailsFromState={orderDetailsFromState}
                        skuId={addedCertonaItemObject.productDetails.skuId}
                        parentRef={lazyMiniCartModalRef}
                      />
                    )}
                  </Suspense>
                </div>
              </TrapFocus>
            </Drawer>
          </div>
        </>
      ) : null}

      {!contentStackSuccess && shouldRenderPreFooter && <LazyPreFooter />}
      {locale !== countryCodes.mx && locale !== countryCodes.ptBr && !contentStackSuccess && (
        <EmailSignup />
      )}
      {contentStackSuccess && contentStackData.seoTags && (
        <CMSSeoTags seoData={contentStackData.seoTags} />
      )}
      {homeHead.length ? <HeadTemplate content={homeHead} isPreview={isPreview} /> : null}
    </>
  );
};
