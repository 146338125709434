/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getAxiosForClient } from '@/lib/axios';
import {
  type QueryClient,
  type QueryFunctionContext,
  type UseQueryOptions,
  useQuery,
} from '@tanstack/react-query';
import type { FormattedContentStackHomepage } from '@/features/contentstack/interface';
import { getContentStackHomePageContent } from '@/features/contentstack/lib/home/getContentStackHomePageContent'; // eslint-disable-line no-restricted-imports
import { type PageContextType } from '@/next-types';
import { useSegments } from '@/features/header/api/getSegments';
import { countryCodes } from '@/constants/locale';
import { useLocale } from '@/hooks/useLocale';

const URL = `/api/contentstack/home`;

const getHomePageV2Content = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getHomePageV2Key>>) => {
  const [, , { segments }] = queryKey;
  const response = await getAxiosForClient().get<FormattedContentStackHomepage>(URL, {
    params: {
      segments: segments.join(','),
    },
  });
  return selector(response.data);
};

const selector = (data: FormattedContentStackHomepage) => data;

export const getHomePageV2Key = (segments: string[]) => ['cms', 'home', { segments }] as const;

export const useHomePageV2Content = (
  options: Omit<
    UseQueryOptions<
      FormattedContentStackHomepage,
      unknown,
      FormattedContentStackHomepage,
      ReturnType<typeof getHomePageV2Key>
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { data: segments, isSuccess } = useSegments();
  const locale = useLocale();
  return useQuery({
    queryKey: getHomePageV2Key(segments?.sort() ?? []),
    queryFn: getHomePageV2Content,
    enabled: (options.enabled ?? true) && (isSuccess || locale === countryCodes.ptBr),
  });
};

export const prefetchHomePageV2Content = (
  queryClient: QueryClient,
  context: PageContextType,
  userSegments: string[]
) => {
  return queryClient.prefetchQuery({
    queryKey: getHomePageV2Key(userSegments),
    queryFn: () => getContentStackHomePageContent(context.req, context.query, userSegments),
  });
};
