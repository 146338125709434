/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { type ContentStackImage } from '@/features/contentstack/interface';
import { useResponsiveImageData } from '@/features/contentstack/hooks/useResponsiveImageData';
import { Picture } from '@/components/Picture/Picture';

type Props = {
  image: ContentStackImage;
};

const sizePerDevice = {
  desktop: {
    height: 58,
    width: 58,
  },
  tablet: {
    height: 38,
    width: 38,
  },
  mobile: {
    width: 38,
    height: 38,
  },
};

export function FeaturedIcon({ image }: Props) {
  const imageData = useResponsiveImageData({ image, sizePerDevice });

  return (
    <Picture
      mobile={imageData.mobile}
      tablet={imageData.tablet}
      desktop={imageData.desktop}
      altText={image.image_alt_text ?? ''}
    />
  );
}
