/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { getAxiosForClient } from '@/lib/axios';
import { type QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';
import type { CitrusCarouselResponse } from '../interface';
import { useAppState } from '@/stores/AppState';
import { useFeatureFlag } from '@/features/globalConfig';
import { getDecodedCookies } from '@/utils/cookie';
import { getCitrusCatalogId } from '../utils/getCitrusCatalogId';
import environmentConfig from '@/config/environment';
import { getCitrusProxyConfiguration } from '../lib/getCitrusProxyConfiguration';

const URL = `/api/citrus/home-spa`;
const PROXY_URL = '/v1/ads/generate';

const getCitrusHomepageCarouselDataWithProxy = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getCitrusHomepageCarouselKey>>) => {
  const [, , , { eCookieId, vehicleId }] = queryKey;
  const cookies = getDecodedCookies(['eCookieId', 'rewardsId', 'forceCitrusProd']);
  const rewardsId = cookies.get('rewardsId');
  const forceCitrusProd = !!cookies.get('forceCitrusProd');
  const productFilters =
    vehicleId && vehicleId !== '0' ? [['vehicleid:universal', `vehicleid: ${vehicleId}`]] : [];
  const { baseURL, axiosConfig } = getCitrusProxyConfiguration();

  const response = await getAxiosForClient().post<CitrusCarouselResponse>(
    `${baseURL}${PROXY_URL}`,
    {
      customerId: rewardsId || null,
      sessionId: rewardsId || eCookieId,
      placement: 'homepage-product',
      catalogId: getCitrusCatalogId(forceCitrusProd),
      maxNumberOfAds: 8,
      productFilters,
      options: {
        filterMode: 'AndOr',
      },
    },
    axiosConfig
  );
  return selector(response.data);
};

const getCitrusHomepageCarouselData = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getCitrusHomepageCarouselKey>>) => {
  const [, , , { eCookieId, vehicleId }] = queryKey;

  const params = {
    eCookieId,
    vehicleId,
  };

  const response = await getAxiosForClient().get<CitrusCarouselResponse>(URL, { params });
  return selector(response.data);
};

const selector = (data: CitrusCarouselResponse): CitrusCarouselResponse => data;

const getCitrusHomepageCarouselKey = (vehicleId: string | undefined, eCookieId: string) =>
  [
    'citrus',
    'home',
    'carousel',
    {
      eCookieId,
      vehicleId,
    },
  ] as const;

export const useCitrusHomepageCarouselData = (
  select?: (headerData: CitrusCarouselResponse) => CitrusCarouselResponse
) => {
  const preferredVehicle = usePreferredVehicle();
  const { eCookieId } = useAppState().state;
  const citrusEnabled = useFeatureFlag('IS_CITRUS_ENABLED') === 'true';
  const CITRUS_PROXY_DISABLED = environmentConfig.CITRUS_PROXY_DISABLED;
  return useQuery({
    enabled: citrusEnabled,
    queryKey: getCitrusHomepageCarouselKey(preferredVehicle?.catalogVehicleId, eCookieId),
    queryFn: !CITRUS_PROXY_DISABLED
      ? getCitrusHomepageCarouselDataWithProxy
      : getCitrusHomepageCarouselData,
    select,
  });
};
