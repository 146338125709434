/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getAxios } from '@/lib/axios';
import {
  type TopNavProductDataResultsModel,
  type TopNavProductDataResultsResponse,
} from '../interface';
import { showXMPreviewDate } from '@/utils/showXMPreviewDate';
import { requestBaseURLGCP } from '@/config/serviceAPI';
import { type AxiosInstance } from 'axios';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import { createQuery } from '@/utils/createReactQuery';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import { useDeviceType } from '@/utils/useDeviceType';

const PRODUCT_DATA_URL = `${requestBaseURLGCP}/external/product-discovery/browse-search/v1/navigation-menus`;

export type NavigationMenusOptions = {
  includeSubCategoryResponse: boolean;
  locale: Locale;
  preview: boolean;
};

const getProductDataResults = async (
  options: NavigationMenusOptions,
  axiosInstance?: AxiosInstance
) => {
  const { locale, includeSubCategoryResponse, preview } = options;

  const country = getCountryFromLocale(locale);

  const customerType = 'B2C';
  const salesChannel = 'ECOMM';

  const response = await getAxios(axiosInstance).get<TopNavProductDataResultsResponse>(
    PRODUCT_DATA_URL,
    {
      params: {
        country,
        customerType,
        includeSubCategoryResponse,
        preview,
        salesChannel,
      },
    }
  );
  return topNavDataSelector(response.data);
};

const {
  useData,
  prefetch: prefetchProductDataTopNavMenu,
  getFromCache: getProductDataTopNavMenuFromCache,
} = createQuery<TopNavProductDataResultsModel, NavigationMenusOptions>(
  'topNavProductData',
  getProductDataResults
);

export const topNavDataSelector = (
  data: TopNavProductDataResultsResponse
): TopNavProductDataResultsModel => {
  const rootCategories =
    data.categories?.map((item) => ({
      topNavServletPath: item.taxonomyPath,
      displayName: item.displayName ?? '',
      text: item.displayName ?? '',
      rank: item.rank ?? 0,
      catName: item.displayName ?? '',
      seoUrl: item.taxonomyPath,
      nId: item.taxonomyId,
      parentNid: item.taxonomyId,
      parentUrlState: item.canonicalPath ?? '',
      chldCategories:
        item.childCategories?.map((childCategory) => ({
          NId: childCategory.taxonomyId,
          seoUrl: childCategory.canonicalPath,
          label: childCategory.displayName,
          refPageType: childCategory.level,
          taxonomyPath: childCategory.taxonomyPath,
        })) ?? [],
      popularCategories:
        item.popularCategories?.map((popularCategory) => ({
          NId: popularCategory.documentId,
          seoUrl: popularCategory.canonicalPath ?? '',
          label: popularCategory.displayName ?? '',
          refPageType: popularCategory.pageType,
          taxonomyPath: popularCategory.taxonomyPath,
        })) ?? [],
    })) ?? [];

  return {
    rootCategories,
  };
};

export const useProductDataTopNavMenu = <T = TopNavProductDataResultsModel>({
  includeSubCategoryResponse = false,
  select,
}: {
  includeSubCategoryResponse?: boolean;
  select?: (data: TopNavProductDataResultsModel) => T;
} = {}) => {
  const locale = useLocale();
  const deviceType = useDeviceType();

  return useData({
    includeSubCategoryResponse: deviceType === 'bot' || includeSubCategoryResponse,
    locale,
    preview: showXMPreviewDate(),
    select,
  });
};

export { prefetchProductDataTopNavMenu, getProductDataTopNavMenuFromCache };
